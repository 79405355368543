




























































































import Vue from "vue";

import TextField from "@/components/formbuilder/TextField.vue";
import TextAreaField from "@/components/formbuilder/TextAreaField.vue";
import SelectField from "@/components/formbuilder/SelectField.vue";
import FormFieldList from "@/components/formbuilder/FormFieldList.vue";
import FormFieldSettings from "@/components/formbuilder/FormFieldSettings.vue";
import { Business, FormField, Role } from "@/types";
import FormBackgroundImage from "@/components/formbuilder/FormBackgroundImage.vue";
import formBuilderStoreModule from "@/store/modules/form";
import { createNamespacedHelpers } from "vuex";

const { mapActions: formActions } = createNamespacedHelpers("FORM_BUILDER");

export default Vue.extend<any, any, any, any>({
  name: "FormBuilder",
  components: {
    TextField,
    TextAreaField,
    SelectField,
    FormFieldList,
    FormFieldSettings,
    FormBackgroundImage,
  },
  data: () => ({
    tab: 0,
    name: "",
    fields: [] as FormField[],
    fieldIndex: -1,
    showUploadBgDialog: false,
    image: undefined as undefined | File,
    formNameRules: [(v: string) => !!v || "Form name is required"],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...formActions(["createForm", "updateForm"]),
    addField(field: FormField) {
      this.fields.push({
        ...field,
        ...{
          settings: {
            label: "",
            showPlaceholder: false,
            placeholder: "",
            isRequired: false,
            showHint: false,
            hint: "",
          },
        },
      });
      if (field.options) {
        this.fields[this.fields.length - 1].options = field.options;
      }
      this.fieldIndex = this.fields.length - 1;
      this.tab = 1;
    },
    setFieldIndex(index: number) {
      this.fieldIndex = index;
      this.tab = 1;
    },
    deleteField(index: number) {
      this.fields.splice(index, 1);
      this.fieldIndex = -1;
    },
    displayImage(image: File) {
      this.showUploadBgDialog = false;
      this.image = image;
      const reader = new FileReader();
      reader.onloadend = () => {
        const fields = document.getElementById("form-fields") as HTMLElement;

        fields.style.backgroundImage = `url(${reader.result})`;
      };

      reader.readAsDataURL(this.image);
    },
    removeOption(index: number) {
      this.fields[this.fieldIndex].options.splice(index, 1);
    },
    addOption() {
      this.fields[this.fieldIndex].options.push("Option");
    },
    saveForm() {
      this.createForm({
        businessId: (this.role.business as Business)._id,
        formFields: this.fields,
        name: this.name,
      }).then((form) => {
        if (form) {
          if (this.image) {
            const data = new FormData();
            data.append("file", this.image);
            this.updateForm({ id: form._id, form: data });
          }
          this.$router.push(`/forms/${form._id}`);
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("FORM_BUILDER")) {
      this.$store.registerModule("FORM_BUILDER", formBuilderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FORM_BUILDER");
  },
});
